import React, { useState } from "react";
import "./index.css";
import { handleSubmit } from "./utils/helpers";

const categories = [
  {
    title: "Kitchen Equipments",
    icon: "ph_oven.svg",
  },
  {
    title: "Raw Materials",
    icon: "fluent_food-28-regular.svg",
  },
  {
    title: "Packaging Solutions",
    icon: "hugeicons_package.svg",
  },
  {
    title: "Food Consultants & chefs",
    icon: "solar_chef-hat-linear.svg",
  },
  {
    title: "Ready to eat & cook products",
    icon: "mdi_food-takeout-box-outline.svg",
  },
  {
    title: "Regulatory Consultants & Food Testing Labs",
    icon: "solar_test-tube-linear.svg",
  },
  {
    title: "Beverages",
    icon: "material-symbols-light_emoji-food-beverage-outline.svg",
  },
  {
    title: "Indian Sweets & Bakery",
    icon: "ep_dessert.svg",
  },
  {
    title: "Health & Wellness Products",
    icon: "mage_heart-health.svg",
  },
];

// Add phone validation function
const isValidPhone = (phone) => {
  if (!phone) return true; // Optional field
  return /^\d{10}$/.test(phone);
};

function App() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openFaq, setOpenFaq] = useState(null);

  // Add email validation function
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailEnter = (e) => {
    if (e.key == "Enter") {
      if (isValidEmail(formData.email)) {
        setIsModalOpen(true);
      }
    }
  };

  // Add phone number formatting
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digits
    if (value.length <= 10) {
      // Limit to 10 digits
      setFormData({ ...formData, phone: value });
    }
  };

  return (
    <div className="min-h-screen bg-[#F9F9F9] pb-20 lg:pb-10 w-full overflow-hidden">
      {/* Main Content */}
      <div>
        <div className="bg-[#181716] min-h-[300px] w-[150%] lg:w-full relative left-[-25%] lg:left-0 rounded-b-[50%] lg:rounded-none top-[-50px] lg:top-0 lg:min-h-[624px] px-12 flex items-end justify-center">
          <img
            src="/images/food-adda-logo.svg"
            alt="food adda logo"
            className="hidden lg:block absolute left-1/2 transform -translate-x-1/2 top-[60px] lg:left-[8%]"
          ></img>
          <img
            src="/images/food-adda-logo.svg"
            alt="food adda logo"
            className="block lg:hidden relative mb-6"
          ></img>
          <img
            src="/images/coming-soon.svg"
            alt="food adda logo"
            className="absolute top-[8%] lg:top-0 left-[8%] lg:right-[8%] lg:left-auto size-1/2 lg:object-none lg:size-min"
          ></img>

          {/* Desktop version remains unchanged */}
          <div className="flex-col mb-10 max-w-xl pb-6 flex">
            <h1 className="text-white text-[64px] leading-[78px] text-center px-5 hidden lg:block">
              Your <span className="text-[#F4D300]">one-stop portal </span>for
              all food related things.
            </h1>
            <p className="text-[#ABABAB] font-light text-center mt-3 hidden lg:block">
              From small businesses to large-scale enterprises, we empower food
              industry professionals & businesses with a network to source,
              connect, and grow.
            </p>
            <div className="z-50 mx-2 lg:mx-0 flex mt-10 bg-white p-1 rounded-md fixed bottom-4 left-0 right-0 lg:static lg:bottom-auto px-1 border-1 lg:border-none shadow-md lg:shadow-none">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-3 bg-white text-black placeholder-gray-500 rounded-l-md focus:outline-none"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                value={formData.email}
                onKeyDown={handleEmailEnter}
              />
              <button
                className={`px-6 py-3 bg-[#F4D300] text-black font-medium rounded-md whitespace-nowrap ${
                  !isValidEmail(formData.email)
                    ? "opacity-60 cursor-not-allowed"
                    : ""
                }`}
                onClick={() => setIsModalOpen(true)}
                disabled={!isValidEmail(formData.email)}
              >
                Join Waitlist
              </button>
            </div>
          </div>
        </div>

        {/* Categories Section */}
        <div className="mt-0 lg:mt-8 font-dm-sans flex flex-col md:px-44">
          <div className="block lg:hidden px-4">
            <h1 className="text-black text-[34px] leading-[40px] font-normal text-left">
              Your{" "}
              <span className="text-[#F4D300] font-extrabold">
                one-stop portal{" "}
              </span>
              for all food related things.
            </h1>
            <p className="text-[#ABABAB] font-light mt-2 text-sm">
              From small businesses to large-scale enterprises, we empower food
              industry professionals & businesses with a network to source,
              connect, and grow.
            </p>
          </div>
          <h2 className="hidden lg:block mb-12 py-4 italic text-center text-[44px] leading-[58px] font-extrabold">
            Launching Soon...
          </h2>

          <h2 className="block lg:hidden mb-6 lg:mb-12 mt-4 py-2 italic text-center text-[34px] leading-[44px] font-extrabold bg-[#F4D300]">
            Launching Soon!
          </h2>
          <p className="font-light text-normal lg:text-2xl text-[#434343] px-4 lg:px-0">
            Whether you’re a{" "}
            <span className="font-bold">
              food enthusiast, entrepreneur, or industry professional
            </span>
            , we’ve got you covered.
          </p>
          <p className="font-light text-normal lg:text-2xl text-[#434343] mt-3 lg:mt-8 px-4 lg:px-0">
            At <span className="font-bold">FoodAdda</span>, we bring together a
            vast ecosystem of{" "}
            <span className="font-bold">food-related products, services</span>,
            and <span className="font-bold">solutions</span> to cater to your
            every need.
          </p>
          <div className="mt-10 lg:mt-20 px-4 lg:px-0">
            <h2 className="text-2xl lg:text-[42px] font-light text-black font-lato">
              Our Extensive <span className="font-bold">Categories</span>
            </h2>
            <div className="overflow-x-auto pb-6 custom-scrollbar">
              <div className="flex gap-6 mt-6 lg:mt-12 min-w-max">
                {categories.map((category) => (
                  <div
                    key={category.title}
                    className="rounded-full gap-1 cursor-pointer bg-white size-[150px] font-medium flex leading-[20px] flex-col p-4 text-center items-center justify-center shrink-0 hover:shadow-lg transition-shadow"
                  >
                    <img
                      src={`/images/icons/${category.icon}`}
                      className="size-6"
                      alt={category.title}
                    />
                    <span>{category.title}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="mt-10 lg:mt-16 flex flex-col px-6 md:px-44">
          <h2 className="text-2xl lg:text-[42px] font-light text-[#434343] font-lato">
            What we <span className="font-bold">offer</span>
          </h2>
          <div className="flex gap-14 lg:gap-6 font-dm-sans mt-20 flex-wrap flex-col lg:flex-row">
            <div className="hover:shadow-lg transition-shadow max-w-[370px] min-h-[300px] pb-3 bg-white rounded-lg relative flex flex-col pt-20 text-center px-8">
              <img
                src="/images/icons/ph_handshake-light.svg"
                className="top-0 size-20 rounded-md absolute translate-y-[-40%] translate-x-[-50%] left-1/2"
              />
              <p className="text-xl leading-[27px] font-bold text-[#242424]">
                B2B (Business to Business)
              </p>
              <p className="text-sm leading-[18px] text-[#757575] mt-4">
                We know food businesses need reliable and affordable suppliers.
                FOODADDA.IN connects buyers and suppliers through exclusive B2B
                partnerships, making it easy to source anything related to food
                with just one click.
              </p>
            </div>
            <div className="hover:shadow-lg transition-shadow max-w-[370px] min-h-[300px] pb-3 bg-white rounded-lg relative flex flex-col pt-20 text-center px-8">
              <img
                src="/images/icons/ph-b2c.svg"
                className="top-0 size-20 rounded-md absolute translate-y-[-40%] translate-x-[-50%] left-1/2"
              />
              <p className="text-xl leading-[27px] font-bold text-[#242424]">
                B2C (Business to Consumer)
              </p>
              <p className="text-sm leading-[18px] text-[#757575] mt-4">
                Explore a variety of delicious food products with ease. Using
                our simple portal and mobile app, customers can browse and
                explore a variety of spices, snacks, health foods, daily
                staples, and ready-to-eat items. Our subscription model helps
                connect your business to customers all over the country.
              </p>
            </div>
            <div className="hover:shadow-lg transition-shadow max-w-[370px] min-h-[300px] pb-3 bg-white rounded-lg relative flex flex-col pt-20 text-center px-8">
              <img
                src="/images/icons/ph_horeca.svg"
                className="top-0 size-20 rounded-md absolute translate-y-[-40%] translate-x-[-50%] left-1/2"
              />
              <p className="text-xl leading-[27px] font-bold text-[#242424]">
                HoReCa.
              </p>
              <p className="text-sm leading-[18px] text-[#757575] mt-4">
                Hospitality, Restaurants, and Catering, shaping trends in food
                consumption, convenience, and quality.
              </p>
            </div>
          </div>
        </div>

        {/* FAQ Accordion */}
        <div className="mt-10 lg:mt-20 flex flex-col px-6 md:px-44">
          <h2 className="text-2xl lg:text-[42px] font-light text-[#434343] font-lato mb-8">
            FAQs
          </h2>

          <div className="space-y-4">
            <div className="bg-white rounded-lg">
              <button
                className="w-full flex justify-between items-center p-4"
                onClick={() => setOpenFaq(openFaq === 1 ? null : 1)}
              >
                <span className="text-lg font-medium text-left lg:text-center">
                  When will Foodadda.in be available?
                </span>
                <span
                  className="transform transition-transform duration-200"
                  style={{
                    transform:
                      openFaq === 1 ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  <img src="/images/icons/mingcute_down-line.png"></img>
                </span>
              </button>
              {openFaq === 1 && (
                <div className="p-4 pt-0">
                  <p className="text-gray-600">
                    Early access to Foodadda.in will begin in Q2 2025.
                  </p>
                </div>
              )}
            </div>

            <div className="bg-white rounded-lg">
              <button
                className="w-full flex justify-between items-center p-4 "
                onClick={() => setOpenFaq(openFaq === 2 ? null : 2)}
              >
                <span className="text-lg font-medium text-left lg:text-center">
                  How much will Foodadda.in subscription cost?
                </span>
                <span
                  className="transform transition-transform duration-200"
                  style={{
                    transform:
                      openFaq === 2 ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  <img src="/images/icons/mingcute_down-line.png"></img>
                </span>
              </button>
              {openFaq === 2 && (
                <div className="p-4 pt-0">
                  <p className="text-gray-600">
                    We're still in the process of finalizing all pricing, Join
                    our early access wait list to stay up to date
                  </p>
                </div>
              )}
            </div>

            <div className="bg-white rounded-lg">
              <button
                className="w-full flex justify-between items-center p-4 "
                onClick={() => setOpenFaq(openFaq === 3 ? null : 3)}
              >
                <span className="text-lg font-medium text-left lg:text-center">
                  How can I find out more?
                </span>
                <span
                  className="transform transition-transform duration-200"
                  style={{
                    transform:
                      openFaq === 3 ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  <img src="/images/icons/mingcute_down-line.png"></img>
                </span>
              </button>
              {openFaq === 3 && (
                <div className="p-4 pt-0">
                  <p className="text-gray-600">
                    We'll be sending regular updates to our early access wait
                    list over the coming months. Join the list now and stay in
                    the loop!
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8 lg:mt-14 flex flex-col font-lato px-6 md:px-44">
          <h2 className="text-2xl lg:text-[42px] font-light text-[#434343] font-lato">
            Join our <span className="font-bold">waitlist</span>
          </h2>
          <p className="font-dm-sans text-sm lg:text-lg text-[#4c4c4c] mt-0 lg:mt-2">
            We would love to have you on FoodAdda! 😊
          </p>

          <div className="hidden lg:flex mt-8 bg-white p-0 lg:p-1 rounded-md w-full lg:max-w-[575px] border border-none lg:border-[#e1e1e1] shadow-none lg:shadow-md ">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full px-2 lg:px-4 py-3 bg-white text-black placeholder-gray-500 rounded-l-md focus:outline-none"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              value={formData.email}
              onKeyDown={handleEmailEnter}
            />
            <button
              className={`px-4 lg:px-6 py-3 bg-[#F4D300] text-black font-medium rounded-md whitespace-nowrap ${
                !isValidEmail(formData.email)
                  ? "opacity-60 cursor-not-allowed"
                  : ""
              }`}
              onClick={() => setIsModalOpen(true)}
              disabled={!isValidEmail(formData.email)}
            >
              Join Waitlist
            </button>
          </div>
        </div>

        {/* Email Input */}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 z-[999]">
          <div className="bg-white rounded-lg p-8 max-w-md w-full">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">Join Waitlist</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500"
              >
                &times;
              </button>
            </div>

            <form
              onSubmit={(e) =>
                handleSubmit(
                  e,
                  formData,
                  setSubmitStatus,
                  setFormData,
                  setIsSubmitting,
                  setIsModalOpen
                )
              }
            >
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Your Name (optional)"
                  className="w-full px-4 py-2 rounded-lg border focus:border-blue-500 focus:outline-none"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <input
                  type="email"
                  placeholder="Email Address *"
                  required
                  className={`w-full px-4 py-2 rounded-lg border focus:border-blue-500 focus:outline-none ${
                    formData.email && !isValidEmail(formData.email)
                      ? "border-red-500"
                      : ""
                  }`}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                {formData.email && !isValidEmail(formData.email) && (
                  <p className="text-red-500 text-sm mt-1">
                    Please enter a valid email address
                  </p>
                )}
                <input
                  type="tel"
                  placeholder="Phone Number (optional)"
                  className={`w-full px-4 py-2 rounded-lg border focus:border-blue-500 focus:outline-none ${
                    formData.phone && !isValidPhone(formData.phone)
                      ? "border-red-500"
                      : ""
                  }`}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                />
                {formData.phone && !isValidPhone(formData.phone) && (
                  <p className="text-red-500 text-sm mt-1">
                    Please enter a valid 10-digit phone number
                  </p>
                )}
                <button
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !isValidEmail(formData.email) ||
                    (formData.phone && !isValidPhone(formData.phone))
                  }
                  className="w-full px-4 py-2 rounded-lg bg-[#F4D300] text-black font-semibold focus:outline-none disabled:opacity-50"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>

            {submitStatus && (
              <p className="mt-4 text-green-600">{submitStatus}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
