// Replace these with your actual Google Form field IDs
const FORM_ID = "1FAIpQLSeZOUJl6iT76bFuGTldHAonOcTWaNGFYr8w2coAkyprtBaD8g";
const EMAIL_ID = "entry.1622024208";
const PHONE_ID = "entry.830089700";
const NAME_ID = "entry.1647772656";

export const handleSubmit = async (
  e,
  formData,
  setSubmitStatus,
  setFormData,
  setIsSubmitting,
  setIsModalOpen
) => {
  e.preventDefault();
  const formUrl = `https://docs.google.com/forms/d/e/${FORM_ID}/formResponse`;
  setIsSubmitting(true);

  try {
    const formBody = new FormData();
    formBody.append(NAME_ID, formData.name);
    formBody.append(EMAIL_ID, formData.email);
    formBody.append(PHONE_ID, formData.phone);

    await fetch(formUrl, {
      method: "POST",
      mode: "no-cors",
      body: formBody,
    });

    // Since we're using no-cors, we can't actually check the response
    // Instead, we'll just assume success if we get here
    setFormData({ name: "", email: "", phone: "" });
    setSubmitStatus("Thanks for submitting your details!");
    setTimeout(() => {
      setIsModalOpen(false);
    }, 1000);
  } catch (error) {
    console.error("Submission error:", error);
    setSubmitStatus("Something went wrong. Please try again.");
  } finally {
    // Always make sure to reset isSubmitting
    setIsSubmitting(false);
  }
};
